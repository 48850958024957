<template>
  <div class="intro-wrapper row no-gutters" :class="{ ['fade-out']: isDone }">
    <div class="container">
      <div class="intro-header px-4 px-lg-0">
        <h1 v-if="status.type === 0">{{$t('Failed to set up your FileCloud site')}}</h1>
        <h1 v-else-if="step >= 4 && status.progress >= 100">{{$t('Ready to start discovering?')}}</h1>
        <h1 v-else>{{$t('Setting up your FileCloud site...')}}</h1>

        <h4 v-if="status.type === 0">{{$t('Unfortunately, we had issues trying to deploy FileCloud, please try again later or contact support.')}}</h4>
        <h4 v-else-if="step < 2" v-html="$t(`While we are deploying your site, make it yours by <b>customizing how it looks</b>.`)"></h4>
        <h4 v-else-if="step < 4" v-html="$t(`Set up your organization by <b>adding your team members to FileCloud</b>.`)"></h4>
        <h4 v-else-if="step >= 4 && status.progress >= 100">Your FileCloud site is now ready, hit <b>"Let's Go"</b> when you're ready as well.</h4>
        <h4 v-else-if="step < 6">{{$t(`Please wait while we finish your site deployment.`)}}</h4>
      </div>
            
      <transition name="intro" :duration="800">
        <div
          v-if="step === 0 && status.type !== 0"
          key="customization"
          class="intro-body has-transition"
        >
          <div
            class="
              col
              my-5
              d-flex
              align-items-center
              flex-column-reverse flex-lg-row
            "
          >
            <div
              class="
                col-12
                px-0 px-lg-3
                mx-md-auto
                col-md-8 col-lg-7
                mt-4 mt-lg-0
              "
            >
              <div class="intro-form-wrapper">
                <form class="container-fluid" @submit="onSubmitCustomization">
                  
                  <Loader full :loading="isProcessing" />
                  
                  <div v-if="errors._message" class="alert alert-danger">
                    {{errors._message}}
                  </div>

                  <div class="row no-gutters">
                    <InputBox
                      id="website-name"
                      v-model="form.websiteName"
                      :label="$t('Website Name')"
                      :focus="step === 0"
                      type="text"
                      auto-select
                      @focus="onFocus('website-name')"
                    />
                  </div>

                  <div class="row no-gutters">
                    <div class="form-group col-sm">
                      <label for="website-theme">
                        {{$t('Default Theme')}}
                      </label>
                      <div class="input-group">
                        <Icon
                          class="form-select-icon"
                          family="fas"
                          name="chevron-down"
                        />
                        <select v-model="form.theme" class="form-control">
                          <option v-for="theme, index in themes" :key="theme.name" :value="index">{{theme.name}}</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="row no-gutters">
                    <InputBox
                      id="company-logo"
                      :label="$t('Company Logo')"
                      :description="$t('PNG, recommended 200x50 pixels')"
                      accept="image/png"
                      type="file"
                      optional
                      :errors="errors"
                      @input="onChangeLogo"
                      @focus="onFocus('company-logo')"
                    />
                  </div>

                  <div class="row no-gutters">
                    <InputBox
                      id="background-image"
                      :label="$t('Background Image')"
                      :description="$t('PNG / JPG')"
                      accept="image/png, image/jpeg"
                      type="file"
                      optional
                      :errors="errors"
                      @input="onChangeBackground"
                      @focus="onFocus('background-image')"
                    />
                  </div>

                  <div class="row no-gutters mb-4">
                    <div class="form-group col-sm">
                      <label for="">{{$t('Branding Color')}}</label>
                      <Slider v-model="currentColor" @input="onChangeColor" />
                    </div>
                  </div>

                  <div
                    class="
                      row
                      no-gutters
                      flex-row-reverse
                      d-sm-flex
                      justify-content-between
                      align-items-center
                    "
                  >
                    <div class="col-lg-12 text-right">
                      <button type="button" class="btn btn-link" @click="next({clearCustomization: true})">
                        {{ $t('Set up later') }}
                      </button>
                      <button type="submit" class="btn btn-primary">
                        {{ $t('Next') }}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-12 px-0 px-lg-3 col-lg-5 d-flex justify-content-center">
              <div class="mini-site-wrapper">
                
                <div class="mini-site" :class="{ [themes[form.theme].value]: true }">
                  <div class="mini-site-header">
                    <div v-if="form.websiteName" class="page-title">{{form.websiteName}}</div>
                  </div>
                  <div class="mini-site-body">
                    <div class="logo">
                      <img
                        v-if="
                          (!isDarkMode && src.logo) ||
                          (isDarkMode && !src.darkLogo && src.logo)
                        "
                        :src="src.logo"
                        alt=""
                      />
                      <img
                        v-else-if="isDarkMode && src.darkLogo"
                        :src="src.darkLogo"
                        alt=""
                      />
                      <Logo v-else />
                    </div>
                    <div class="page-form"></div>
                    <div class="page-btn"></div>
                  </div>
                  <div class="mini-site-bg">
                    <img
                      v-if="src.background"
                      :src="src.background"
                      class="login__main-bg main-bg"
                    />
                    <img
                      v-else
                      src="../assets/aurora-bg.jpg"
                      class="login__main-bg main-bg"
                    />
                  </div>

                </div>
                <div class="site-mode">
                  {{$t('Your Site Preview')}}
                </div>
                
              </div>
            </div>
          </div>

        </div>

        <div v-if="step === 2 && status.type !== 0" key="invite" class="intro-body">
          <div class="col my-5 d-flex align-items-center">
            <div class="col-12 px-0 px-lg-3 col-md-7 mx-auto">
              <div class="intro-form-wrapper">
                <form class="container-fluid" @submit="onSubmitUsers">
                  
                  <Loader full :loading="isProcessing" />
                  
                  <div v-if="errors._message" class="alert alert-danger">
                    {{errors._message}}
                  </div>

                  <div class="row no-gutters">
                    <InputBox
                      id="emails"
                      ref="email"
                      v-model="form.emails"
                      :label="$t('Invite your team')"
                      :placeholder="
                        $t('Type an email address...')
                      "
                      :focus="true"
                      :errors="errors"
                      type="text"
                      @keydown="onEmailKeyDown"
                    >
                      <a
                        slot="input-append"
                        class="d-flex align-items-center px-3 text-primary"
                        :class="{
                          disabled: false
                        }"
                        @click="onAddUser"
                      >
                        {{$t('Add user')}}
                      </a>
                    </InputBox>
                  </div>

                  <ul class="users-list">
                    <li>
                      <div class="avatar">
                        <Icon family="fas" name="user" />
                      </div>
                      <div class="user-info">
                        <h5>{{$t('You')}}</h5>
                        <p>{{user.email}}</p>
                      </div>
                    </li>
                    <li v-for="userToAdd, index in users" :key="userToAdd">
                      <div class="avatar"><Icon family="fas" name="user" /></div>
                      <div class="user-info">
                        <p>{{userToAdd}}</p>
                      </div>
                      <div class="btn btn-icon" @click="onRemoveUser(index)"><Icon name="times" /></div>
                    </li>
                  </ul>

                  <div
                    class="
                      row
                      no-gutters
                      flex-row-reverse
                      d-sm-flex
                      justify-content-between
                      align-items-center
                    "
                  >
                    <div class="col-lg-12 text-right">
                      <button type="button" class="btn btn-link" @click="next">
                        {{ $t('Set up later') }}
                      </button>
                      <button type="submit" class="btn btn-primary">
                        {{ $t('Next') }}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

        </div>

        <div v-if="step === 4 || status.type === 0" key="progress" class="intro-body">
          <div class="col my-5 d-flex align-items-center">
            <div class="col-12 px-0 px-lg-3 col-md-10 mx-auto text-center">
              <div class="animation-wrapper">
              
                <lottie-player
                  v-if="animation"
                  ref="player"
                  class="lottie"
                  loop
                  autoplay
                  mode="normal"
                  style="width: 100%"
                  :src="animation"
                  debug
                ></lottie-player>
              </div>
                            
              <div v-if="status.type === 0" class="progress-wrapper">
                <h5 v-if="errors._message" class="mb-2 text-danger">{{errors._message}}</h5>
                <SupportBlock />
              </div>
              <div v-else-if="status.progress < 100" class="progress-wrapper">
                <transition name="slide-loader">
                  <h5 v-if="hasAudio && phrases[phraseIndex].indexOf('Bohemian Rhapsody') > -1" :key="phraseIndex"><a @click="onClickBohemian">{{ phrases[phraseIndex] }}...</a></h5>
                  <h5 v-else :key="phraseIndex">{{ phrases[phraseIndex] }}...</h5>
                </transition>
                <div class="progress">
                  <div class="progress-bar" :style="`width:${status.progress}%;`"></div>
                </div>
              </div>
              <div v-else class="progress-wrapper">
                <h4>{{$t('An email has been sent with your site login information for future access.')}}</h4>
                <button class="btn btn-primary px-5 mx-auto" @click="onDone">{{$t('Let\'s Go')}}</button>
              </div>
            </div>
          </div>

        </div>
      </transition>
    </div>

    <vue-headful :title="$t('FileCloud Customer Portal')" />
  </div>
</template>

<script>

import Vue from 'vue';
import _ from 'lodash';
import Logo from '../assets/logo.svg';
import AnimationJson from '../assets/rocket.json';
import InputBox from '../components/InputBox';
import SupportBlock from '../components/Partials/SupportBlock';
import Loader from 'common/components/Loader';
import Icon from 'common/components/Icon';

import { Slider } from 'vue-color';
import { themes, setColor } from 'common/utils/themes';
import { parseFileThumb } from 'common/utils/files';

import { replaceColor } from 'lottie-colorify';

export default {
  name: 'Auth',
  components: {
    Loader,
    Logo,
    Icon,
    InputBox,
    Slider,
    SupportBlock
  },
  data() {
    return {
      animation: null,
      currentColor: '#0067ad',
      currentRgbColor: [1,106,174],
      isDarkMode: false,
      show: true,
      progress: 20,
      step: 0,
      isDone: false,
      phrases: [
        'Counting sheep',
        'Pushing pixels',
        'Making the server go beep boop',
        'Humming "Bohemian Rhapsody" from the Queen',
        'Engineering the Eiffel Tower',
        'Sleeping with the fishes',
        'Writing autobiography',
        'Animating the next Star Wars movie',
        'Repainting the Mona Lisa'
      ],
      phraseIndex: 0,
      form: {
        logo: null,
        darkLogo: null,
        websiteName: '',
        theme: '0',
        emails: ''
      },
      users: [],
      src: {
        logo: null,
        darkLogo: null,
        background: null,
      },
      themes,
      status: {
        type: 1,
        progress: 0
      },
      interval: null,
      errors: {},
      token: null,
      siteToken: null,
      hasAudio: !!window.Audio
    }
  },
  computed: {
    user() {
      return this.$store.state.core.user;
    },
    isReady() {
      return this.$store.state.core.ready;
    },
    isProcessing() {
      return this.$store.state.loading['sites/setCustomization'] ||
      this.$store.state.loading['sites/setCustomizationFile'] ||
      this.$store.state.loading['core/resumeSession'] ||
      this.$store.state.loading['sites/inviteUsers'];
    }
  },
  async mounted() {
      
    // extract tokens from url
    const [ token, siteToken ] = atob(this.$route.params.token).split('|');
    
    this.token = token;
    this.siteToken = siteToken;
    
    let parsedToken = this.parseJwt(this.token);
          
    parsedToken.email = 'tst.com'      
            
    // resume session via token if not logged in
    if(this.user && (!this.user.id || (parsedToken && this.user.email !== parsedToken.email))) {
      
      if(!this.$route.params.token) {
        this.$router.push('/login');
        return;
      }
      
      // resume session
      const response = await this.$store.dispatch('core/resumeSession', { token, settings: { noContent: true } })
      
      if(!response.ok) {
        this.$router.push('/login');
      }
            
    }
        
    this.onCheckTick();
    
    // start interval each 5s
    this.interval = setInterval(this.onCheckTick, 2500);
                
    // pre fill
    this.form = {
      logo: null,
      darkLogo: null,
      websiteName: (typeof this.user.teamname === 'string') ? this.user.teamname : '',
      theme: 0
    };
    
    // shuffle phrases
    this.phrases = _.shuffle(this.phrases);
    
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    async onSubmitCustomization(e) {
      e.preventDefault();
      e.stopPropagation();
      
      this.errors = {};
      
      // upload logo
      if(this.form.logo) {
        
        const logoResponse = await this.$store.dispatch('sites/setCustomizationFile', {
          file: this.form.logo,
          scope: 'login'
        });
        
        // TODO: temporary
        await this.$store.dispatch('sites/setCustomizationFile', {
          file: this.form.logo,
          scope: 'maindark'
        });
        
        if(logoResponse.data && logoResponse.data.indexOf('OK') === -1 && logoResponse.data.indexOf('uploaded') === -1) {
          this.errors = {
            ['company-logo']: {
              message: logoResponse.message
            }
          }
          return;
        }
        
      }
    
      // upload bg
      if(this.form.background) {
        
        const bgResponse = await this.$store.dispatch('sites/setCustomizationFile', {
          file: this.form.background,
          scope: 'loginbg'
        });
        
        if(bgResponse.data && bgResponse.data.indexOf('OK') === -1 && bgResponse.data.indexOf('uploaded') === -1) {
          this.errors = {
            ['background-image']: {
              message: bgResponse.message
            }
          }
          return;
        }
        
      }

      const response = await this.$store.dispatch('sites/setCustomization', {
        websitename: this.form.websiteName,
        defaulttheme: this.form.theme,
        colorscheme: this.currentColor,
      });
      
      if(!response.ok) {
        
        this.errors = {
          ['_message']: {
            message: response.error
          }
        }
        
        return;
        
      }
      
      this.next();
    },
    async onSubmitUsers(e) {
      
      e.preventDefault();
      e.stopPropagation();
      
      this.errors = {};
      
      if(this.users.length > 0) {

        const response = await this.$store.dispatch('sites/inviteUsers', {
          users: this.users.join(',')
        });
        
        if(!response.ok) {
          
          this.errors = {
            ['_message']: {
              message: response.error
            }
          }
          
          return;
          
        }

      }
      
      this.next();
      
    },
    onDone() {
      this.isDone = true;
      
      // do the redirect magic
      setTimeout(() => {
        window.location.href = `https://${this.user.teamname}.filecloudonline.com/ui/core/index.html#/?token=${this.siteToken}`;
      }, 2500);
      
    },
    next(settings) {
      
      // reset to default color
      if(settings && settings.clearCustomization) {
        setColor('#0067ad');
        this.currentColor = '#0067ad';
        this.currentRgbColor = [1,106,174];  
      }
      
      this.step++;
      setTimeout(() => {
        this.step++;
        if(this.step === 4) {
          this.initAnimation();
        }
      }, 800);
    },
    initAnimation() {
      //this.animation = JSON.stringify(replaceColor([0, 103, 173], this.currentRgbColor, AnimationJson));
      this.animation = JSON.stringify(
        replaceColor([0, 103, 173], this.currentRgbColor, AnimationJson)
      );

      this.$nextTick(() => {
        this.$refs.player.addEventListener('frame', (e) => {
          if (e.detail.frame >= 52 && !this.isDone) {
            this.$refs.player.seek(44);
          }
        });
      });
    },
    async onChangeLogo(data) {
      this.isDarkMode = false;
      if (data) {
        this.src.logo = await parseFileThumb(data[0]);
        this.form.logo = data[0];
      } else {
        this.src.logo = null;
        this.form.logo = null;
      }
    },
    onFocus(section) {
      if (section === 'company-logo-dark') {
        this.isDarkMode = true;
      } else {
        this.isDarkMode = false;
      }
    },
    async onChangeBackground(data) {
      this.isDarkMode = false;
      if (data) {
        this.src.background = await parseFileThumb(data[0]);
        this.form.background = data[0];
      } else {
        this.src.background = null;
        this.form.background = null;
      }
    },
    onChangeColor(data) {
      this.currentColor = data.hex;
      this.currentRgbColor = [data.rgba.r, data.rgba.g, data.rgba.b];
      setColor(data.hex);
    },
    // check trial status tick
    async onCheckTick() {
      
      this.phraseIndex++;
            
      if(this.phraseIndex > this.phrases.length - 1) {
        this.phraseIndex = 0;
      }
      
      const trialResponse = await this.$store.dispatch('sites/getOnlineTrialStatus');
            
      const { data } = trialResponse;
            
      // has error
      if(data.result === 0) {
        this.status = {
          type: 0,
          error: data.message
        }
        return;
      } else if(data.result === 2) {
        clearInterval(this.interval);
      }
      
      this.status = {
        type: data.result,
        progress: parseInt(data.message)
      }
            
    },
    onEmailKeyDown(e) {
      if(e.key === 'Enter') {
        this.onAddUser(e);
      }
    },
    onAddUser(e) {
      this.errors = {};
      
      e.preventDefault();
      
      const emailToAdd = this.$refs.email.value;
      const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if(!emailToAdd.match(emailRegex)) {
        this.errors = {
          ['emails']: {
            message: 'Please enter a valid email address'
          }
        }
        return;
      }
      
      if(emailToAdd === this.user.email) {
        this.errors = {
          ['emails']: {
            message: 'You are already added'
          }
        }
        return;
      }
      
      if(this.users.indexOf(emailToAdd) > -1) {
        
        this.errors = {
          ['emails']: {
            message: 'The user is already added'
          }
        }
        
        return;

      }
      
      this.users.push(emailToAdd);
      this.form.emails = '';
    },
    onRemoveUser(index) {
      Vue.delete(this.users, index)
    },
    onClickBohemian() {
    
      if(window.Audio) {
        var audio = new Audio('/sounds/queen.mp3'); // path to file
        audio.play();
      }
      
    },
    parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
  }
}
</script>
