export const themes = [
  { name: 'Light', value: 'light-mode', color: '#076795' },
  { name: 'Dark', value: 'fc-dark-mode', color: '#076795' },
  { name: 'Sunburn', value: 'orange-fc-dark-mode', color: '#fd6207' },
  { name: 'Caffeine', value: 'caffeine-fc-dark-mode', color: '#c33333' },
  { name: 'Midnight', value: 'midnight-fc-dark-mode', color: '#456d95' },
  { name: 'Mohave', value: 'mohave-mode', color: '#862d2d' },
];


export function luminanace(r, g, b) {
    let a = [r, g, b].map(function (v) {
        v /= 255;
        return v <= 0.03928
            ? v / 12.92
            : Math.pow( (v + 0.055) / 1.055, 2.4 );
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

// darken and lighten
export function lightenDarkenColor(colorCode, amount) {
    let usePound = false;

    if (colorCode[0] == "#") {
        colorCode = colorCode.slice(1);
        usePound = true;
    }
    const num = parseInt(colorCode, 16);
    let r = (num >> 16) + amount;

    if (r > 255) {
        r = 255;
    } else if (r < 0) {
        r = 0;
    }

    let b = ((num >> 8) & 0x00FF) + amount;

    if (b > 255) {
        b = 255;
    } else if (b < 0) {
        b = 0;
    }

    let g = (num & 0x0000FF) + amount;

    if (g > 255) {
        g = 255;
    } else if (g < 0) {
        g = 0;
    }
    let color = (g | (b << 8) | (r << 16)).toString(16);
    while (color.length < 6){
      color = 0 + color;
    }
    return (usePound ? '#' : '') + color;
}

export function hexToRgb(hex) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [ parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16) ] : null;
}

// set theme to body
export function setColor(color, el = null) {
  if (typeof color !== 'string') color = themes[0].color;
  
  const rgb = hexToRgb(color);

  const foreGorund = (Math.round(((parseInt(rgb[0]) * 299) + (parseInt(rgb[1]) * 587) + (parseInt(rgb[2]) * 114)) /1000)) > 125;
  const htmlTag = (el) ? el.style : document.documentElement.style;
  htmlTag.setProperty(
    '--color-primary--foreground',
    foreGorund ? '#000' : '#fff'
  );
  htmlTag.setProperty('--color-primary', color);
  htmlTag.setProperty('--color-primary--hover', lightenDarkenColor(color, -10));
  htmlTag.setProperty('--color-primary--focus', lightenDarkenColor(color, -20));
  htmlTag.setProperty('--color-primary--shadow', `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, .5)`);
}


export function setTheme( theme ) {
  const doc = document.body.classList;
  doc.forEach(o => {if (o.includes('-mode')) doc.remove(o);});
  theme = Number.isInteger(theme) ? theme : 0;
  doc.add(themes[theme].value);
}

export function loadCss( customCssFile ) {
    let file = document.createElement('link');
    file.rel = 'stylesheet';
    file.id = customCssFile;
    file.type = 'text/css';
    file.href = customCssFile + "?time=" + (new Date()).getTime();
    document.head.appendChild(file);
}

export function preventClickOnPdfTrial(isHostedTrial) {
    if(!isHostedTrial) return;
    const css = `.pdf-app .annotationLayer .linkAnnotation>a:hover {
            opacity : 1 !important;
            background: transparent !important; 
            box-shadow: none !important;
            font-family: sans-serif;
            font-size: 0.5rem;
            cursor: text;
        }
        .pdf-app .annotationLayer .linkAnnotation>a:active {
            pointer-events: none !important;
        }`;
    let head = document.head || document.getElementsByTagName('head')[0],
    style = document.createElement('style');

    head.appendChild(style);

    style.type = 'text/css';
    style.appendChild(document.createTextNode(css));
}
